.project-card-view {
  background-color: #141686;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0px 2px 4px rgba(68, 3, 233, 0.1);
  }
  
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .card-body .tech-stack {
    color: rgb(231, 64, 64);
  }
  
  .view-button {
    width: 100%;
    margin-top: 20px;
  }

  /* project-cards.css */



.card-img {
  width: 20%; /* Set the width of the image to 100% of its container */
  height: auto; /* Automatically adjust the height to maintain the aspect ratio */
}
